import { COLORS } from "../styles/constants"
import PropTypes from "prop-types"
import React from "react"

const Footer = ({ email, organization }) => {
  const emailLink = `mailto:${email}?subject=Peyda Track`

  return (
    <footer
      style={{
        padding: "1rem",
        backgroundColor: COLORS.lightGray,
      }}
    >
      <div
        style={{
          display: "grid",
          alignItems: "center",
          justifyContent: "space-between",
          gridTemplateColumns: "repeat(auto-fit, minmax(200px, 240px))",
          padding: "1rem 2rem",
          fontSize: ".90rem",
        }}
      >
        <div style={{ fontWeight: 700 }}>
          <a
            style={{ textDecoration: "none", color: COLORS.mulberry }}
            href={emailLink}
          >
            Kontakt
          </a>
        </div>
        <div style={{ color: COLORS.gray }}>
          © {new Date().getFullYear()}
          {` `}
          {organization}
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  email: PropTypes.string,
  organization: PropTypes.string,
}

Footer.defaultProps = {
  email: ``,
  organization: ``,
}

export default Footer
