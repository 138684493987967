/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import "../styles/default.css"

import Footer from "./footer"
import PropTypes from "prop-types"
import React from "react"

const Layout = ({ email, organization, children }) => {
  return (
    <>
      <div
        style={{
          padding: "0 1rem",
        }}
      >
        <main>{children}</main>
      </div>
      <Footer email={email} organization={organization} />
    </>
  )
}

Layout.propTypes = {
  email: PropTypes.string,
  organization: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Layout
