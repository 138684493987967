export const COLORS = {
  lightWhite: "#FBFDFF",
  lightGray: "#F3F5F6",
  mediumGray: "#B0B8BA",
  gray: "#A0A4A6",
  babyBlue: "#50CAF9",
  blue: "#5368f1",
  mediumRedViolet: "#B52384",
  mulberry: "#D147A3",
}

export const GRADIENT = `linear-gradient(to top, ${COLORS.mulberry}, ${COLORS.mediumRedViolet})`

export const BORDER_RADIUS = "10px"
